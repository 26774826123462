import { render, staticRenderFns } from "./LeisureCompNewDonghangGameLinks.vue?vue&type=template&id=2908abf2&scoped=true&"
import script from "./LeisureCompNewDonghangGameLinks.vue?vue&type=script&lang=js&"
export * from "./LeisureCompNewDonghangGameLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2908abf2",
  null
  
)

export default component.exports