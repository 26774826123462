<template>

    <div class="leisure_button_group_sub">
        <router-link tag="button" class=" bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_MGM_BAKARA}"
                     :to="{path:'/leisure/mgm_bakara', query: {t: new Date().getTime()}}">MGM 바카라</router-link>
<!--        <router-link tag="button" class=" bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_MGM_ODDEVEN}"-->
<!--                     :to="{path:'/leisure/mgm_gostop', query: {t: new Date().getTime()}}">MGM 고스톱</router-link>-->

        <!--<router-link tag="button" class=" bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_MGM_ODDEVEN}"
                     :to="{path:'/leisure/mgm_oddeven', query: {t: new Date().getTime()}}">MGM 홀짝</router-link>-->

    </div>



</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompMgmGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>